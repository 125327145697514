.message__section {
  //   width: 60rem;
  width: 100%;
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  // border: 1px solid red;
  @media screen and (max-width: 1000px) {
    width: 100vw;
  }

  &.user {
    background: var(--ai-user-stripe-line);
  }
  &.ai {
    // background: var(--ai-prompt-bg-color);
    padding: 2.5rem 1.5rem;
  }
  .center__item {
    // display: flex;
    justify-content: center;
    // width: 60rem;
    width: 65%;
    gap: 2rem;
    .display__handle{
      display: flex;
      justify-content: center;
    }
    @media screen and (max-width: 1000px) {
      width: 100%;
      justify-content: flex-start;
      padding: 0 2rem;
    }
    @media screen and (max-width: 500px) {
      padding-left: 0;
      padding-right: 0;
    }

    .named__avatar {
      width: 3.5rem !important;
      height: 3.5rem !important;
      min-width: 3.5rem !important;
      min-height: 3.5rem !important;
      border-radius: 50%;
      background-color: var(--ai-user-avatar);
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .message__and__buttons__div {
      // width: 56rem;
      // margin-top: -0.8rem;
      width: 100%;

      @media screen and (max-width: 1000px) {
        width: calc(100% - 5rem);
      }
    }
    .message {
      font-family: "Inter";
      font-style: normal;
      font-weight: 300;
      font-size: 15px;
      line-height: 28px;
      
      pre {
        max-width: 100%;
        overflow-x: auto;
        white-space: pre-wrap;
        
      }

      code {
        max-width: 100%;
        display: block;
        word-wrap: break-word;
        white-space: pre-wrap;
        font-family: "Inter",sans-serif !important;

      }
      
      p {
        margin-top: 1rem;
      }
      ol {
        padding-left: 1.5rem;
        margin-left: 0;
      }
      li {
        margin-top: 1rem;
      }
      ul {
        padding-left: 1.5rem;
        margin-left: 0.5;
        // li {
        //   border: 1px solid red;
        // }
      }
    }
    .question__fontWeight {
      font-weight: 500;
      text-align: justify;
      line-height: unset;
      p {
        font-weight: 500;
        text-align: justify;
        line-height: unset;
      }
    }
    .image__avatar {
      width: 3.5rem !important;
      height: 3.5rem !important;
      min-width: 3.5rem !important;
      min-height: 3.5rem !important;
      border-radius: 50%;
      background-color: var(--ai-boat-avatar);
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 1.8rem;
        margin-left: 2px;
      }
    }
    .ai_buttons {
      display: flex;

      .ai_message__button {
        all: unset;
        font-size: 14px;
        line-height: 24px;
        text-decoration-line: underline;
        color: var(--twentyfour-bg-color);
        text-underline-offset: 2px;
        cursor: pointer;
        margin-top: 1.5rem;
        margin-right: 1.2rem;
      }
    }
  
  }
  .attachments__wrapper{
    margin-bottom: 2rem;
    
    .attactments__container{
      display: flex;
      gap: 2rem;
      flex-wrap: wrap;
    }

  }
  .loader_item {
    display: flex;
    justify-content: flex-start;
    width: 60rem;
    gap: 2rem;
    align-items: center;
    &.margin_top {
      margin-top: -3rem !important;
    }
  }
}

.snippet {
  margin-left: 2rem;
}
.dot-carousel {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ebebeb;
  color: #ebebeb;
  box-shadow: 9984px 0 0 0 #ebebeb, 9999px 0 0 0 #ebebeb, 10014px 0 0 0 #ebebeb;
  animation: dot-carousel 1.5s infinite linear;
}

@keyframes dot-carousel {
  0% {
    box-shadow: 9984px 0 0 -1px #ebebeb, 9999px 0 0 1px #ebebeb,
      10014px 0 0 -1px #ebebeb;
  }
  50% {
    box-shadow: 10014px 0 0 -1px #ebebeb, 9984px 0 0 -1px #ebebeb,
      9999px 0 0 1px #ebebeb;
  }
  100% {
    box-shadow: 9999px 0 0 1px #ebebeb, 10014px 0 0 -1px #ebebeb,
      9984px 0 0 -1px #ebebeb;
  }
}
