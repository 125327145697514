.drop_down_wrapper {
  width: 100%;
  border-radius: 8px;
  padding: 1rem 2rem;
  position: relative;
  border: 1px solid var(--primary-border-color);
  .faq__header__section {
    all: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
    .selected__option {
      margin-right: 10px;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: var(--primary-dropdown-text);
    }
    .down__icon {
      width: 2rem;
      cursor: pointer;
      color: var(--sixth-text-color);
    }
  }

  .select__options {
    display: none;

    width: inherit;
    border: 1px solid var(--primary-border-color);
    position: absolute;
    border-radius: 8px;
    top: 5.5rem;
    padding: 0.5rem 1.5rem;
    left: 0;
    background: var(--fifth-bg-color);
    &.active {
      display: block;
    }
    .optioin__ol {
      list-style: none;
      padding: 0;
    }
    .option {
      list-style: none;
      // padding: 0;
      // width: inherit !important;
      display: block;
      font-weight: 400;
      font-size: 14px;
      margin: 1rem 0;
      // line-height: 25px;
      cursor: pointer;
      &:hover {
        color: var(--seventh-colorful-text);
      }
    }
  }
  .isOptionInTop{
    top: -9.5rem;
  }
  .isDropdownBg{
    background: var(--alpha-ternary-dropdown-bg-color);
    border: 1px solid var(--alpha-primary-dropdown-box-border-color);
  }
  .aabg{
    background-color: var(--alpha-ternary-dropdown-bg-color);

  }
  .tacBg{
    background-color: var(--header-drop-bg-color);

  }
}
.paddingHandle{
  padding: 0.4rem 0.8rem;
}