.message__input__section {
  position: relative;
  width: 100%;
  input {
    padding-right: 4rem !important;
    width: 100%;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
  .right_arrow {
    position: absolute;
    right: 12px;
    // width: 2rem;
    stroke-width: 1px;
    bottom: 11px;
    color: var(--primary-placeholder-color);
    cursor: pointer;
  }
  .right__arrow__icon{
    color: var(--alpha-primary-text-color);
    background: var(--primary-placeholder-color);
    // background-color: red;
    background: var(--ai-prompt-bg-color) ;
  }
  button{
    padding: 0;
    height: 3.6rem;
    width: 3.6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: var(--ai-prompt-bg-color) ;
    border: 1px solid var(--black-trns-border);
    &:disabled{
      cursor: not-allowed;
      background: var(--ai-top-up-btn-bg-color);
      svg{
        color: var(--alpha-primary-text-color);;
        
      }
    } 
  }
}
.chat__box {
  overflow: hidden;
  all: unset;
  min-height: 5rem !important;
  word-break: break-all;
}

.chat__textarea {
  color: #1f1e1e !important;
  resize: none;
  width: 100%;
  height: 5rem;
  max-height: 15rem;
  transition: height 0.3s;
  // padding: 0.9rem 1.5rem;
  padding: 1.2rem 1.5rem;
  padding-top: 1.3rem;
  background: #f8fafb;
  border-radius: 0.5rem;
  border: none;
  outline: none;

  color: var(--primary-input-text-color) !important;
  background: var(--primary-input-bg-color);

  padding-right: 8rem;
  text-align: justify;
  font-family: "Inter", sans-serif !important;
  // border: 1px solid var(--primary-hover-color);
  border: 1px solid var(--primary-border-color);

  &::placeholder {
    color: var(--primary-placeholder-color);
  }

  &:hover {
    border: 1px solid var(--primary-focus-color);
  }

  &:focus {
    box-shadow: 0 0 5px var(--primary-focus-color);
    border: 1px solid var(--primary-focus-color);
  }

  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    display: none;
  }
  &::-webkit-scrollbar-track {
    display: none;
  }
}
.chat__textarea__input{
  background: var(--alpha-primary-input-bg-color) ;
  border: 1px solid var(--alpha-ternary-input-border-color) ;
  &::placeholder{
    color: var(--alpha-primary-text-color);
  }

}


.new__input__wrapper{
  // margin-top: 20rem;
  // padding: 1.6rem;
  // margin-top: auto;
 
  .new__input__container{
  
      display: flex;
      flex-direction: column;
      gap: 1.6rem;
      background: var(--background-color);
      border-radius: 0.5rem;

    .input__file__preview{
      background: var(--alpha-primary-input-bg-color);
      border-radius: 0.5rem;
      min-height: 10rem;
      // padding: 1rem 0;
      border: 1px solid var(--black-trns-border);
    }
    .file__previewer{
      display: flex;
      gap: 0.8rem;
      // padding: 1.6rem;
      flex-wrap: wrap;
      
    }
    .filespaddingHandle{
      padding: 1.6rem;
    }
    .message__input__section{
      .chat__textarea{
        border: 1px solid transparent;
        &::placeholder {
          color: var(--primary-placeholder-color);
        }
      
        &:hover {
          border: 1px solid transparent;
        }
      
        &:focus {
          box-shadow: 0 0 5px transparent;
          border: 1px solid transparent;
        }
      

      }
      .chat__textarea__input{
        background: var(--alpha-primary-input-bg-color) ;
        border: 1px solid transparent;
        &::placeholder{
          color: var(--alpha-primary-text-color);
        }
      
      }
      .right_arrow{

      }
    }
    .input__bottom{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 1.5rem;
      padding-bottom: 0.8rem;

      .bottom__left{
        display: flex;
        align-items: center;
        gap: 0.8rem;

      }

      .bottom__right{
        // background: red;
        button{
          all: unset;
          padding: 0;
          height: 3.6rem;
          width: 3.6rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background: var(--ai-prompt-bg-color) ;
          border: 1px solid var(--black-trns-border);
          &:disabled{
            cursor: not-allowed;
            background: var(--ai-top-up-btn-bg-color);
            svg{
              color: var(--alpha-primary-text-color);;
              
            }
          } 
          
        }
       

        .right__arrow__icon{
          // cursor: pointer;
          svg{
             color: var(--alpha-primary-text-color);
             
          }
  
        }

      }

   

    }
    .file__select{
      padding: 2.4rem;
      box-sizing: border-box;
      width: fit-content;
      background: var(--ai-prompt-bg-color) ;
      // background: red;
      cursor: pointer;
      border: 1px solid var(--black-trns-border);
      display: flex;
      gap: 0.8rem;
      align-items: center;
      border-radius: 0.8rem;
      margin-bottom: 0;
      // width: 33%;
      .left__section{
        h3{
          font-family: "Inter",sans-serif;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0%;


        }
        p{
          font-family: "Inter",sans-serif;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0%;
        }
        #file__input{
          display: none;

        }
      }
      .icon__section{
        svg{
          height: 2rem;
          width: 2rem;
        }
      }

    }
    .plus__file__circle{
      // margin-left: 1.5rem;
      padding: 0;
      height: 3.6rem;
      width: 3.6rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      #file__input{
        display: none;

      }
      .icon__section{
        height: 1.8rem;
        width: 1.8rem;
      }
      
    }

    .chat__history__title{
      font-family: "Inter",sans-serif;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0%;
    }
    .chat__history{

      .chat__history__item{
        padding: 1.5rem;
        background: var(--ai-prompt-bg-color) ;
        // background: red;
        cursor: pointer;
        border: 1px solid var(--black-trns-border);
        display: flex;
        gap: 0.8rem;
        align-items: center;
        border-radius: 0.8rem;
        // width: 50%;
        .history__content{
          h3{
            font-family: "Inter",sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0%;


          }
          p{
            font-family: "Inter",sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0%;
            margin-top: 0.8rem;
          }
        }
        .history__icon{

          svg{
            height: 2rem;
            width: 2rem;

          }
        }

      }

    }

  }
  .tac__new__input__container{
    background: var(--primary-input-bg-color);
  }

}