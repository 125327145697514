.ai__feedback__wrapper{
    // margin:  1.6rem;
    .ai__feedback__info{
        display: flex;
        gap: 0.4rem;
        align-items: center;
        justify-content: space-between;
        padding: 1.6rem;
        padding-bottom: 0;
        .left{
            // background: linear-gradient(95.42deg, rgba(255, 255, 255, 0.09) 8.59%, rgba(82, 80, 80, 0.11) 94.35%);
            // ;
            // padding: 0.8rem;
            // border-radius: 30px;
           
            // color: #fff;
            

            p{
                font-family: "Inter",sans-serif;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                letter-spacing: 0%;

            }
            span{
                font-family: "Inter",sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0%;
                color: var(--primary-placeholder-color)

            }
        }
        .right{
            // font-family: "Inter",sans-serif;
            // font-weight: 400;
            // font-size: 12px;
            // line-height: 18px;
            // letter-spacing: 0%;
            // opacity: 0.8;
            // color: #fff;
            display: flex;
            align-items: center;
            gap: 0.8rem;
            .circle{
                // background: linear-gradient(95.42deg, rgba(255, 255, 255, 0.09) 8.59%, rgba(82, 80, 80, 0.11) 94.35%);
                background: linear-gradient(144.41deg, #FE7268 10.2%, #53C4C7 90.26%);
                height: 5rem;
                width: 5rem;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                // opacity: 0.8;
                color: #fff;
            }
            .text__info{
                p{
                    font-family: "Inter",sans-serif;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 18px;
                    letter-spacing: 0%;
    
                }
                span{
                    font-family: "Inter",sans-serif;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    letter-spacing: 0%;
                    color: var(--primary-placeholder-color)
    
                }
            }
        }

    }

    .ai__feedbacklist{
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
        padding-top: 1.6rem;
        border-top: 1px solid var(--primary-border-color);
        margin-top: 1.6rem;
        padding-left: 3.2rem;
        padding-right: 1.6rem;
        height: calc(38.5rem - 8rem);
        li{
            font-family: "Inter",sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0%;
        }
    }

}