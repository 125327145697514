.mid__content {
    width: 100%;
    margin-left: 3rem;

    @media screen and (max-width:1100px) {
      margin: 2rem 0;
      
    }

    @media screen and (max-width: 426px) {
      margin: 0.5rem 0;
      width: 100%;
    }
    .video__section {
      background: linear-gradient(
        132.06deg,
        rgba(255, 255, 255, 0.09) 2.05%,
        rgba(82, 80, 80, 0.114) 68.52%
      );

      .video__content {
        position: relative;
        padding: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        .video__loading__section {
          position: absolute;
          left: 1rem;
          right: 1rem;
          bottom: 1rem;
          top: 1rem;
          background: #000;
          opacity: 0.4;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        video {
          height: 100%;
          // width: 54rem;
          width: 100%;

          @media screen and (max-width:1440px) {
            width: 54rem;
            
          }

          @media screen and (max-width:1370px) {
            width:100%;
            
          }

          @media screen and (max-width:1100px) {
            width: 100%;
            
          }
        }
        .react-player {
          transform: "scaleX(-1)" !important;
        }
        .video__controller__handle{
          width: 100%;
          @media screen and (max-width:1100px) {
            // display: block;
            
          }
          .video__controller__section {
            position: absolute;
            left: 1rem;
            bottom: 2rem;
            right: 2rem;
            width: calc(100% - 2rem);
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 1.5rem;

            @media screen and (max-width:1100px){
              width: 90%;
              margin-left: 2rem;
            //   justify-content: center;
              // margin: 2rem 0;
              
            }
            @media screen and (max-width:600px) {
              margin-left: 0;
              
            }

            .play__pause__section {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 4px;

              .play__image {
                width: 2rem;
                cursor: pointer;
              }
            }

            .time__slider__section {
              display: flex;
              align-items: center;
              width: 80%;
            
              .time {
                width: 6.2rem;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                color: #ffffff;
                width: 60px;

                @media screen and (max-width:1370px) {
                  width: 80px;
                  
                }
                
                &.mr-1 {
                  margin-right: 1rem;
                }
              }

              .time__progress__section {
                width: 100%;
                .rc-slider-track {
                  background: #fff;
                }

                .rc-slider-rail {
                  background: rgba(255, 255, 255, 0.5) !important;
                }
                .rc-slider-handle {
                  background: #fff !important;
                  border: 2px solid #fff;
                  &:hover {
                    border-color: #fff;
                  }
                  opacity: 100 !important;
                }
                .rc-slider-handle-dragging {
                  background: white;
                  box-shadow: none !important;
                }
              }
            }

            .volume__and__download__section {
              display: flex;
              align-items: center;
              .volume__section {
                display: flex;
                align-items: center;
                position: relative;
                .volume___controller {
                  margin-bottom: -5px;
                  width: 1.9rem;
                  cursor: pointer;
                  margin-right: 1rem;
                  color: #ffffff;
                //   color: var(--fourth-text-color);
                }
                .volume__slider__div {
                  position: absolute;
                  height: 10.5rem;
                  bottom: 3rem;
                  left: 0;
                  background: rgba(45, 45, 45, 0.65);
                  backdrop-filter: blur(4.90647px);
                  padding: 1.2rem 0.3rem;
                  border-radius: 10px;
                  display: none;
                  &.active {
                    display: block;
                  }

                  .rc-slider-track {
                    background: #fff;
                    // display: none;
                  }

                  .rc-slider-rail {
                    background: rgba(255, 255, 255, 0.5) !important;
                  }
                  .rc-slider-handle {
                    background: #fff !important;
                    border: none !important;
                    &:hover {
                      border-color: #fff;
                    }
                    opacity: 100 !important;
                  }
                  .rc-slider-handle-dragging {
                    background: white;
                    box-shadow: none !important;
                  }
                }
              }

              .download__button {
                width: 1.8rem;
                cursor: pointer;
                margin-bottom: -5px;
                color: #ffffff;
                // color: var(--fourth-text-color);
              }
            }
          }

        }

       
      }
    }
  }