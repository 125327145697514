.chat__projects__wrapper{
    margin-bottom: 1rem;
    .chat__projects__container{
      
        .cp__title{
            font-family: "Inter",sans-serif;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0%;
            color: #94949B;
        }
        .isInAiasistPage{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .cp__title{
                font-size: 1.8rem;
                color: var(--fourth-text-color);

            }
        }
        .chat__projects__lists{
            margin-top: 1.5rem;
            // overflow: auto;
            // max-height: 20rem;
            padding-right: 1rem;
            // min-height: 20rem;
            display: flex;
            flex-direction: column;
            gap: 0.8rem;
            .chat__project__item{
                // all: unset;
                display: flex;
                width: 100%;
                background-color: transparent;
                border: none;
                outline: none;
                justify-content: space-between;
                align-items: center;
                padding: 1rem 0.8rem;
                cursor: pointer;
                &:disabled{
                    cursor: not-allowed;
                }

                &.active{
                    // background: linear-gradient(95.42deg, rgba(255, 255, 255, 0.09) 8.59%, rgba(82, 80, 80, 0.11) 94.35%);
                    .left{
                        svg{
                            color: var(--active-color);
                        }
                        p{
                            color: var(--active-color);
                        }
                    }
                    
                }
            
                &:hover{
                    background: linear-gradient(95.42deg, rgba(255, 255, 255, 0.09) 8.59%, rgba(82, 80, 80, 0.11) 94.35%);
                    .threedot{
                        display: flex;
                    }

                }
                .left{
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                   
                    svg{
                        min-height: 1.6rem;
                        min-width: 1.6rem;
                        max-height: 1.6rem;
                        max-width: 1.6rem;
                        // color: #BABABA;
                        // align-self: flex-start;
                        color: #fff;
                        align-self: flex-start;
                    }
                    p{
                        font-family: "Inter",sans-serif;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0%;
                        // color: #bababa;
                        color: #fff;

    
                    }

                }
                .threedot{
                    position: relative;
                    // z-index: 20;
                    // display: none;
                    span{
                        display: flex;
                        align-items: center;
                    }
                    .morehorizontal{
                        color: #bababa;
                        height: 2rem;
                       
                    }
                    &:hover{
                        .actions__wrapper{
                            display: flex;
                        }    
                    }
                    .actions__wrapper{
                        width: 11.4rem;
                        position: absolute;
                        top: 2rem;
                        right: 0;
                        background: linear-gradient(95.42deg, rgba(255, 255, 255, 0.09) 8.59%, rgba(82, 80, 80, 0.11) 94.35%);
                        display: none;
                        flex-direction: column;
                        border-radius: 0.8rem;
                        z-index: 10;
                        button{
                            cursor: pointer;
                            border: none;
                            display: flex;
                            align-items: center;
                            // justify-content: center;
                            font-family: "Inter",sans-serif;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 20px;
                            letter-spacing: 0%;
                            padding: 1rem 1.6rem;
                            background-color: transparent;
                            gap: 0.8rem;
                            svg{
                                height: 1.8rem;
                                width: 1.8rem;
                                color: #fff;
                            }
                        }

                        .edit__project__btn{
                            color: #fff;
                            // border-bottom: 1px solid var(--black-trns-border);

                        }
                        .delete__project__btn{
                            
                            color: #D90101;
                           
                        }
                        
                    }
                    
                    .isAA{
                        background: #13263e;
                        .edit__project__btn,.delete__project__btn{
                            color: #fff;
                        }

                    }
                    .isTAC{
                        background: #303030;
                        .edit__project__btn,.delete__project__btn{
                            color: #fff;
                        }
                    }
                    
                }
                .threedot__toggle{
                    display: flex;
                    align-items: center;
                }
                .conversation-toggle{
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    svg{
                        height: 1.6rem;
                        width: 1.6rem;
                        color: #fff;
                    }
                    
                }

                
            }
            .isAiassitsPageProjectItem{
                border: 1px solid var(--black-trns-border);
                background: var(--ai-prompt-bg-color);
                .left{
                    
                   
                    svg{
                        color: var(--fourth-text-color);
                        color: #fff;
                       
                    }
                    p{
                        
                        color: var(--fourth-text-color);
    
                    }

                }

            }
            .isTACisAiassitsPageProjectItem{
                .left{
                    
                   
                    svg{
                        color: var(--header-text);
                       
                    }
                    p{
                        
                        color: var(--header-text);
                    }

                }

            }
            .disabled{
                cursor: not-allowed;
                // pointer-events: none;
            }
            .conversation-list{
                display: flex;
                flex-direction: column;
                // gap: 1rem;
                .conversation-item{
                    font-size: 1.4rem;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0%;
                    color: #94949B;
                    font-weight: 400;
                    cursor: pointer;
                    padding: 0.8rem 1.6rem;
                    &.active{
                        color: #fff;
                        background: linear-gradient(95.42deg, rgba(255, 255, 255, 0.09) 8.59%, rgba(82, 80, 80, 0.11) 94.35%);
                    }
                }

            }
        }
        .height__scroll{
            overflow: auto;
            max-height: 20rem;
        }
    }
}