.question__sidebar {
  background: var(--alpha-sixth-bg-color);
  height: 100vh;
  padding: 24px;
  padding-right: 0;
  // width: 44rem;
  width: 30%;
  position: relative;
  @media screen and (max-width: 1000px) {
    display: none;
  }
  .question__sidebar__wrapper {
    .question__header {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      color: #ffffff;
    }
    .question__sidebar__container {
      .question__wrapper {
        max-height: calc(100vh - 46rem);
        overflow-y: auto;
        margin-top: 2.5rem;
        padding-right: 2.4rem;
        &::-webkit-scrollbar {
          width: 7px;
        }
        &::-webkit-scrollbar-thumb {
          -webkit-border-radius: 10px;
          border-radius: 10px;
          background: var( --alpha-secondary-scroll-thumb);
        }
        &::-webkit-scrollbar-track {
          background-color: var( --alpha-secondary-scroll-track);

          -webkit-border-radius: 10px;
          border-radius: 10px;
        }

        .question__div {
          margin-top: 25px;
          // margin-right: 1rem;
          &:first-child{
            margin-top: 0;
          }

          .question__item {
            display: flex;
            cursor: pointer;
            .square__messagebox {
              margin-right: 1.4rem;
              height: 2.4rem;
              width: 2.4rem;
              color: white;
            }
            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: white;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
              // color: var(--fourth-text-color);
            }
            &:hover {
              .square__messagebox {
                color: var(--ternary-table-header-color);
              }
              span {
                color: var(--ternary-table-header-color);
              }
            }
          }
          .question__underline {
            width: 100%;
            height: 1px;
            background: var(--alpha-fourth-divider-color);
            margin: 15px 0;
          }
        }
      }
      .mobile__view__question__wrapper {
        max-height: calc(100vh - 13rem);
      }
      .clear__all {
        text-align: right;
        padding-right: 3rem;
        button {
          all: unset;
          color: var(--alpha-fourth-text-color);
          cursor: pointer;
          font-size: 14px;
          line-height: 17px;
          text-align: right;
        }
      }
      .button__wrapper {
        // width: 100%;
        margin-top: 15px;
        width: 86%;
        position: absolute;
        bottom: 1.5rem;
        button {
          display: flex;
          align-items: center;
          background: #6f6ff1;
          border-radius: 8px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;
          width: 100%;
          padding: 10px 0;
          justify-content: center;
          outline: none;
          border: none;
          cursor: pointer;

          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
}
.custom-tool-tip-ai-popup {
  max-width: 30rem;
  background: var(--alpha-secondary-ttoltip-bg-color);
  border-radius: 8px;
  // color: var(--fourth-text-color);
  color: white;
  position: absolute !important;
  left: 1.5rem !important;
  opacity: 100 !important;
}
