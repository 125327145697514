.preview__audio__wrapper {
  width: 100%;
  margin-top: 0 !important;
  margin: 8px 0px;
  background-color: var(--video-record-bg-color);
  padding: 10px;
  position: relative;
  margin-bottom: 0px;
  position: relative;
  .preview__audio__container {
    width: 100%;
    height: 40rem !important;
    background: #08111c;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    
    /* Optional: Styling the audio element */
    audio {
      width: 50%;
      /* Background color for the audio controls */
      border-radius: 5px;
      color: white; /* Text color for the controls */
    }

    /* Set the background color of the control panel to #56AEFF */
    audio::-webkit-media-controls-panel {
        // background: var(--new-secondary-bg-color);
      background: #fff;
    }

   
  }
  .answer__audio__player{

    .handleAudioPlayerStyle{
        width: 90%;
    }
  }
}
