.chat__sidebar__section {
  height: 100vh;
  width: 27rem;
  // background-color: #0a0a0a;
  background-color: var(--ai-sidebar-color);
  padding: 1.5rem;
  padding-right: 0;
  position: fixed;
  top: 6.5rem;
  z-index: 10;
  @media screen and (max-width: 1000px) {
    display: none;
  }

  .button__padding {
    padding-right: 1rem;
  }
  .rc__title{
    font-family: "Inter",sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0%;
    color: #94949B;
    // margin-top: 1.6rem;

  }

  .custom-tool-tip {
    max-width: 24rem;
    background: var(--ai-sidebar-tooltip-bg) !important;
    border-radius: 8px;
    // color: var(--fourth-text-color);
    color: white;
    position: absolute !important;
    left: 1.5rem !important;
    opacity: 100 !important;
  }

  .custom-react-tooltip-arrow {
    left: 2rem !important;
  }
}

.question_section_wrapper {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  .question__section {
    cursor: pointer;
    display: flex;
    // align-items: center;
    padding: 1.5rem 0;
    color: #fff;
    // color: red;
    &.disabled {
      cursor: not-allowed;
    }

    &.active {
      .message__square__icon {
        // color: #ffc97a;
        color: var(--active-color);
      }
      span {
        // color: #ffc97a;
        color: var(--active-color);
      }
    }

    .message__square__icon {
      width: 2rem;
      margin-right: 1.4rem;
    }
    span {
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &:hover {
      .message__square__icon {
        // color: #ffc97a;
        color: var(--active-color);
      }
      span {
        // color: #ffc97a;
        color: var(--active-color);
      }
    }
    border-bottom: 1px solid var(--ai-sidebar-border);
  }
  .question__section__style {
    &:hover {
      .message__square__icon {
        color: var(--ai-prompt-active-color);
      }
      span {
        color: var(--ai-prompt-active-color);
      }
    }
  }
}

.mobile__view__chat__side__bar {
  display: none;
  @media screen and (max-width: 1000px) {
    display: block;
  }
  position: fixed;
  left: 0;
  top: 6rem;
  z-index: 100;
  background-color: var(--ai-sidebar-color);

  min-height: 100vh;
  // width: 0;
  // padding: 0;
  // box-sizing: border-box;
  // transition: width 0.5s;
  transition: transform 0.5s;
  width: 30rem;
  padding: 2rem;
  transform: translateX(-300px);
  &.active {
  transform: translateX(0);
  }

  .cancel__icon {
    display: flex;
    margin-left: auto;
    width: 2rem;
    color: #667085;
    overflow: hidden;
    visibility: hidden;
    &.active {
      visibility: visible;
    }
  }
}
.clear__div {
  padding-right: 2rem !important;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;

  .clear_conversation__button {
    all: unset;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: var(--clear-conversation);
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
    }
  }
}
.questions__section {
  margin-top: 1.5rem;
  overflow: auto;
  padding-right: 1rem;
  max-height: calc(100vh - 52rem);
}

.chat__custom__scrollbar {
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-button {
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: var(--ai-scroll-thumb);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--ai-scroll-bar-bg);

    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
}
