.create__chat__project__btn__wrapper{
    margin-right: 1.5rem;
    margin-bottom: 1rem;
    &.mr0{
        margin-right: 0;
    }
    &.mb0{
        margin: 0;
        order: 2;
    }
    .create__chat__project__btn{
        all: unset;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.8rem;
        height: 4.4rem;
        box-sizing: border-box;
        width: 100%;
        border-radius: 0.8rem;
        border: 1px solid var(--ai-sidebar-border);
        margin-right: 1.5rem;
        
        span{
            font-family: "Inter",sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0%;
            color: var(--clear-conversation);
        }
        .circle__plus{
            background: var(--primary-color);
            height: 2.4rem;
            width: 2.4rem;
            border-radius: 50%;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            svg{
                height: 1.6rem;
                width: 1.6rem;
            }
            
        }
        
    }
    .isInAiasistPageCreateChatProjectBtn{
        border: 1px solid transparent;
        margin-right: 0;
        gap: 0.8rem;

    }
}
