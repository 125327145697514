.recent__question__container {
    padding: 2rem 3rem;
    background-color: var(--secondary-bg-color);
    margin: 2rem 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
  
    .recent__question__wrapper {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
  
      .recent__question {
        padding-right: 2rem;
        display: flex;
        gap: 10px;
        align-items: center;
  
        p {
          font-size: 16px;
          font-weight: 400;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: var(--ternary-table-header-color);
        }
        .evaluate__done{
          display: flex;
          align-items: center;
          height: 2rem;
          width: 2rem;
          img{
            height: 100%;
            width: 100%;
          }

        }
      }
  
      .recent__attempt__time {
        display: flex;
        justify-content: space-between;
  
        p {
          margin-right: 1rem;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          color: var(--date-txt-color);
          font-size: 12px;
        }
      }
    }
    .question__div{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 30px;
        // color: #FFFFFF;
        color: var(--fourth-text-color);
        margin-top: 1.2rem;
        cursor: pointer;
        

    }
    .recent__answer__content {
      display: flex;
      justify-content: space-between;
      min-height: 380px;
      padding: 2rem 0rem;
      width: 100%;
      margin-top: 1.5rem;
      border-top: 1px solid var(--secondary-table-header-color);

      @media screen and (max-width:1100px) {
        flex-direction: column;
        
      }
  
      @media screen and (max-width: 426px) {
        flex-direction: column;
        justify-content: center;
      }
  
      .left__content {
        min-width: 15%;
        border-right: 1px solid var(--secondary-table-header-color);
        margin-right: 1rem;
        // width: 300px;
        h3 {
          font-weight: 500;
          font-size: 12px;
          color: #6f6f6f;
          padding-bottom: 1rem;
        }
        @media screen and (max-width: 426px) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
          align-items: center;
        }
        .video__title {
          display: flex;
          flex-direction: column;
          .video__counter {
            display: flex;
            align-items: center;
            span {
              cursor: pointer;
              font-weight: 400;
              padding: 1rem 0;
              font-size: 14px;
              // color: var(--instructor-text-color);
              color: #667085;
              text-decoration: underline;
              text-underline-offset: 4px;
              width: 110px;
              max-width: 115px;
             
              sup {
                margin-left: -4px;
              }
  
              &:hover {
                color: #6f6ff1 !important;
              }
            }
            .active__class__color{
              color: #6f6ff1 !important;

            }
            .generate__sharable__link{
              display: flex;
              align-items: center;
              cursor: pointer;
              margin-right: 1rem;
              svg{
                width: 1.5rem;
                color: #bababa;
                margin-bottom: -5px;

              }
            }
            .delete__icon {
              width: 1.5rem;

              margin-bottom: -5px;
              cursor: pointer;
              color: #bababa;
  
              &.active {
                color: #f04438;
              }
              &:hover {
                color: #f04438;
              }
            }
          }
        }
      }
  
      .admin__answer__audio__player{
        width: 100%;
        height: 100%;
        margin-top: 3rem;
        // display: flex;
        // justify-content: center;
        // align-items: center;
      }
      .right__content {
        height: 100%;
        width: 100%;
  
        .form_content {
          height: 100%;
          width: 100%;
          padding-left: 4rem;

          @media screen and (max-width:1100px) {
            padding-left: 0;
            
          }
  
          @media screen and (max-width: 426px) {
            padding-left: 0;
          }
  
          .heading {
            font-weight: 500;
            font-size: 14px;
            color: var(--custom-label-txt);
            padding: 0.5rem 0;
          }
          .admin_rating {
            // margin: 1.4rem 0;
            display: flex;
            margin-top: 1.5rem;
  
            p {
              font-weight: 500;
              font-size: 14px;
              color: var(--custom-label-txt);
            }
            .star {
              padding-left: 0.8rem;
              svg {
                cursor: pointer;
              }
              // span{
              //     color: #FFC600;
  
              // }
            }
          }
          .feebback_btn {
            width: 76px;
          }
        }
      }
    }
    .not_given {
      border-top: 1px solid var(--secondary-table-header-color);
      color: var(--notgivent-text-color);
      padding: 15px 0;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }

    .evaluate-custom-tool-tip {
      // max-width: 12rem;
      // background-color: var(--twentysix-bg-color);
      background-color: #444444;
      border-radius: 8px;
      // border: 2px solid red;
      // color: var(--fourth-text-color);
 
      position: absolute !important;
      margin-left: 3rem;
  
    }
  
    .evaluate-custom-react-tooltip-arrow {
      left: 1.5rem !important;
    }
}
.Bunny__ifreame__content {
  width: 100%;
  margin-left: 3rem;

  @media screen and (max-width:1100px) {
    margin: 2rem 0;
    height: 500px;
    
  }

  @media screen and (max-width: 426px) {
    margin: 0.5rem 0;
    width: 100%;
  }
}
.bunny__frame__wrapper{
  height: 100%;
  width: 100%;
}
  