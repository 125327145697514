.chatbot__section__wrapper{
    width: 100%;
    background:rgba(0, 0, 0, 0.7);;
    height: 100vh;
    position: fixed;
    z-index: 10000000000;
    top: 0;
    @media screen  and (max-width:500px){

    }

    // background: black;
    .chatbot__section__container{
        display: flex;
        width: 100%;
        // border: 1px solid green;
        justify-content: flex-end;

        @media screen and (max-width:860px) {
            height: 100%;
            
        }
 
        .chatbot__section__div{
            display: flex;
           
            // border: 1px solid red;
            width: 1126px;
            @media screen and (max-width:1000px) {
                width: 100%;
                
            }
            .chatbot__section__div__container{
                display: flex;
                width: 100%;
           
                .chatbot__chatting__area{
                   
                    width: 70%;
                    position: relative;
                    max-height: 100vh;
                    
                    // background-color: var(--seventh-bg-color);
                    background: var(--alpha-primary-modal-bg-color);
                    
                    @media screen and (max-width:1000px) {
                        width: 100%;
                        
                    }
                

                }
                .chat__input__area__section{
                    // position: fixed;
                    position: absolute;
                    bottom: 0;
                    min-height: 50px;
                    background-color: var(--alpha-primary-modal-bg-color);
                    border-top: 1px solid transparent;
                    // margin: 2rem;
                    padding: 2rem;
                    padding-top: 0;
                    width: calc(100% - 0rem);
                }
            }

        }
    
    }
}