.preview-item {
    display: flex;
    align-items: center;
    gap: 8px;
    background: var(--ai-user-stripe-line);
    background:linear-gradient(95.42deg, rgba(255, 255, 255, 0.09) 8.59%, rgba(82, 80, 80, 0.11) 94.35%);
    border-radius: 8px;
    padding: 5px;
    min-width: 180px;
    position: relative;
    // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;

    // &:hover {
    //   background: #eaeaea;
    // }

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      top: -5px;
      right: -5px;
      display: flex;
      align-items: center;
      justify-content: center;
      // padding: 5px;
      border-radius: 50%;
      height: 1.8rem;
      width: 1.8rem;
      transition: background 0.3s;
      padding: 0;
      background: var(--ai-prompt-bg-color) ;
      // background: rgba($color: #000, $alpha: 0.7);

      svg {
        width: 12px;
        height: 12px;
        color: var(--fourth-text-color);
        // color: #ff4d4d;
      }
    }
    .name__size{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
      span {
        font-size: 14px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
      }
      p{
        font-size: 1.2rem;
        color: var(--alpha-primary-text-color);
      }

    }
    .isTacFileRemove{
      svg{
        color: var(--header-text);
        
      }
    }


  }