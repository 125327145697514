.without__conv__wrapper{
    .without__onv__container{
      min-height: 85vh;
      
      margin: 2rem;
      max-height: 85vh;
      overflow-y: auto;
      &::-webkit-scrollbar{
        display: none;
      }
      .name__description__section{
        display: flex;
        flex-direction: column;
        gap: 0.8rem;
        margin-bottom: 20rem;
        .p__name{
          display: flex;
          align-items: center;
          svg{
            // height: 1.6rem;
            // width: 1.6rem;
          }
          span{
            font-weight: 600;
            display: flex;
            align-items: center;
            font-size: 1.8rem;
          }

        }
        h3{

        }
        p{
          
        }
      }
      h2{
        font-size: 2.8rem;
        font-weight: 600;
        margin-bottom: 1.6rem;
        text-align: center;
      }
      .width__handle{
        width: 67%;
        margin: 0 auto;
        @media screen and (max-width:1280px) {
            width: 80%;          
        }
        @media screen and (max-width:1000px) {
          width: 100%;
          
        }
      }
      .fromChatBotWidthHandle{
        width: 86%;
        @media screen and (max-width:1280px) {
          width: 100%;          
      }
      @media screen and (max-width:1000px) {
        width: 100%;
        
      }
      }
      .withput__conv__input{
      

      }
      .chat__history__wrapper{
        .chat__history__title{
          font-family: "Inter",sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0%;
          margin-bottom: 1.6rem;
          margin-top: 1.6rem;
        }
        .chat__history{
          display: grid;
          grid-template-columns: repeat(2,1fr);
          gap: 2.4rem;
          max-height: 280px;
          overflow-y: auto;
          @media screen  and (max-width:1000px){
            grid-template-columns: 1fr;
            
          }
          .chat__history__item{
            padding: 1.5rem;
            background: var(--ai-prompt-bg-color) ;
            // background: red;
            cursor: pointer;
            border: 1px solid var(--black-trns-border);
            display: flex;
            gap: 0.8rem;
            align-items: center;
            border-radius: 0.8rem;
            // width: 50%;
            .history__content{
              h3{
                font-family: "Inter",sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0%;
    
    
              }
              p{
                font-family: "Inter",sans-serif;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0%;
                margin-top: 0.8rem;
              }
            }
            .history__icon{
              align-self: flex-start;
              display: flex;
    
              svg{
                height: 2rem;
                width: 2rem;
    
              }
            }
    
          }
    
        }

      }

    }

  }

  .fromChatbotProjectEmptyScreen{
    background: var(--alpha-primary-modal-bg-color);
  }