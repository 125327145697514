.feedback__wrapper {
  width: 45rem;
  margin-left: 3rem;
  background: var(--feedback-bg-color);
  // background: var(--alpha-primary-gradient-bg-color);
  // padding-top: 1.5rem;
  padding: 1.5rem 0;
  // padding-bottom: -1.5rem;
  // height: calc(100% - 5rem);
  border: 1px solid var(--black-trns-border);
  @media screen and (max-width: 1100px) {
    width: 100%;
    margin-left: 0;
    margin-top: 2rem;
    // height: 300px;
  }

  .feedback__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: var(--alpha-ternary-text-color);
    padding-left: 1.5rem;
  }
  .feedback__heading{
    display: flex;
    justify-content: space-between;
    align-items: center;
   
    .feedback__type__btns{
      display: flex;
      border-radius: 0.8rem;
      padding: 0.4rem;
      margin-right: 0.8rem;
      background: var(--alpha-primar-divider-color);
      .feedback__type__btn{
        padding: 0.8rem 1.4rem;
        font-family: "Inter";
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
        border-radius: 0.8rem;
        border: none;
        background-color: transparent;
        outline: none;
        color: var(--fourth-text-color);
        // color: #fff;
        &.active{
          background: var(  --primary-color);
          color: #fff;

        }
      }


    }

  }

  .tab__container {
    .tab__list__section {
      border-bottom: 1px solid (var(--primary-border-color));
      &.no__tabs {
        margin-top: 4.5rem;
      }
      .single__tab {
        display: inline-block;
        cursor: pointer;
        padding: 1rem 1.5rem 1rem 1.5rem;
        // padding-bottom: 1rem;
        background: none;
        outline: none;
        margin-left: -4rem;
        font-size: 14px !important;

        color: var(--primary-input-text-color);
        &:hover {
          color: var(--primary-colorful-text);
          border-bottom: 1px solid var(--primary-tabs-underline-color);
        }
        &.mll {
          margin-left: 2rem;
          @media screen and (max-width: 370px) {
            margin-left: 0;
          }
        }
        sup {
          margin-left: -3px !important;
        }
      }
      .react-tabs__tab--selected {
        border-bottom: 1px solid var(--primary-tabs-underline-color);
        font: bolder;
        color: var(--primary-colorful-text);
      }
    }
    .box__shadow {
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
      margin-top: 2rem;
      margin-left: 0;
    }
    .no__feedback__found {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 38.5rem;
    }
  }
  .scrollable__feedback__list {
    height: 38.5rem;
    overflow: auto;
    padding-right: 20px;
    margin-top: 1rem;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: var(--alpha-scroll-thumb-color);
    }
    &::-webkit-scrollbar-track {
      background-color: var( --alpha-scroll-track-color);
  
      -webkit-border-radius: 10px;
      border-radius: 10px;
    }
  }

  .feedback__form {
    .text__area__form {
      padding: 1.5rem;
    }
    .feedback__button__div {
      padding: 0 1.5rem;
      &.center {
        display: flex;
      }
      .back__icon__div {
        background: var(--alpha-first-bg-color);
        margin-right: 1rem;
        padding: 0 0.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        .back__icon {
          width: 2rem;
        }
      }
      .reply__to__feedback {
        all: unset;
        width: 100%;
        height: 3.5rem;
        background: var(--alpha-primary-btn-bg-color);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--elevtnth-colorful-text);
        cursor: pointer;
      }
      .recored__feedback__icon {
        background: var(--alpha-first-bg-color);
        // width: 3.5rem;
        padding: 0 1rem;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-left: 1rem;
        .audio__mic {
          width: 1.5rem;
        }
      }
    }
  }
}
.single__feedbacK__wrapper {
  margin-left: 2rem;
  display: flex;
  gap: 1.2rem;
  border-bottom: 1px solid var(--alpha-primar-divider-color);
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  .left__section {
    .avatar__name {
      width: 3rem !important;
      height: 3rem !important;
      border-radius: 50%;
      font-weight: 600;
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--twentyeight-bg-color);
      text-transform: capitalize;
    }
    .avatar__name__style{
      background: var(--alpha-fourth-bg-color);
    }
  }
  .right__section {
    width: 100%;
    .top__section {
      display: flex;
      justify-content: space-between;
      position: relative;
      .user__name__role {
        display: flex;
        align-items: baseline;
        .user__name {
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
        }
        .dot {
          display: block;
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background-color: #667085;
          margin: 0 0.5rem;
          // color: #667085;
        }
        .user__role {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #667085;
        }
      }

      span {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
      .three__dot {
        color: #6f6f6f;
        cursor: pointer;
        margin-right: -1rem;
      }
      .select__section {
        display: none;
        position: absolute;
        right: 0;
        top: 3rem;
        width: 121px;

        background: var(--fifth-bg-color);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        padding: 1.5rem;
        z-index: 10;
        &.active {
          display: block;
        }

        .action__divider {
          border-bottom: 1px solid var(--profile-divider-color);
          margin: 1rem 0;
        }
        .select__action {
          display: flex;
          align-items: center;
          cursor: pointer;

          .action__icon {
            width: 1.5rem;
            margin-right: 1rem;
          }
          span {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
      .handleDeleteEditDropdownStyle{
        background: var( --alpha-ternary-dropdown-bg-color);
        .action__divider__style__handle{
          border-bottom: 1px solid var(--alpha-ternary-divider-color);

        }

      }
    }
    .content__section {
      p {
        width: 98%;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
